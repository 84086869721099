import {graphql, useStaticQuery} from "gatsby";


const staticData = ()=>{
    const data = useStaticQuery(graphql`
        query GetData {
            allBrandJson {
                nodes {
                    id
                    name
                    briefDescription
                    longDescription
                    logo
                    icon
                }
            }
            allProductsJson {
                nodes {
                    id
                    description
                    name
                    price
                    order
                }
            }
            allFeaturesJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allSolutionsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allFaqsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allStepsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allTestimonialsJson {
                nodes {
                    id
                    who
                    testimonial
                }
            }
            websiteJson {
                id
                jsonId
                live {
                    functions {
                        actions
                        form {
                            disabled
                            forwardTo
                            buttonText
                            bottomHeader
                            title
                            emailHeader
                            emailHeaderEnabled
                            emailHeaderRequired
                            formHeader
                            formHeaderEnabled
                            formHeaderRequired
                            firstNameLabel
                            firstNameEnabled
                            firstNameRequired
                            lastNameLabel
                            lastNameEnabled
                            lastNameRequired
                            locationLabel
                            locationEnabled
                            locationRequired
                            phoneLabel
                            phoneEnabled
                            phoneRequired
                            dateLabel
                            dateEnabled
                            dateRequired
                            options
                            optionsLabel
                            optionsEnabled
                            optionsRequired
                            multiOptions
                            successMessage
                            redirectUrl
                        }
                        link {
                            enabled
                            buttonText
                            redirectTo
                        }
                        calendly {
                            enabled
                            buttonText
                            redirectTo
                        }
                        phone {
                            enabled
                            buttonText
                            redirectTo
                        }
                    }
                    styling {
                        primaryText
                        buttons
                        header
                        footerBackground
                        footerText
                        background
                        tilesBackground
                    }
                    layout {
                        siteTitle
                        sectionTitles
                        headerType
                        contentAlign
                        buttonShape
                    }
                    meta {
                        googleAdsConversions {
                            leadConversionId
                            leadLabel
                        }
                    }
                }
            }
            allImageSharp {
                edges {
                    node {
                        id
                        original {
                            src
                            height
                            width
                        }
                        fluid(
                            maxWidth: 1100
                        ) {
                            src
                            srcSet
                            base64
                        }
                        resize(
                            width: 1100
                        ) {
                            src
                        }
                    }
                }
            }
        }
    `)

    return {
        brand : data.allBrandJson.nodes[0],
        products : data.allProductsJson.nodes,
        solutions : data.allSolutionsJson.nodes,
        steps : data.allStepsJson.nodes,
        testimonials : data.allTestimonialsJson.nodes,
        features : data.allFeaturesJson.nodes,
        faqs : data.allFaqsJson.nodes,
        website : data.websiteJson,
        images : data.allImageSharp.edges,
    }

}

export default staticData